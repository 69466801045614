<template>
  <div class="home">
    <div class="bar">
      <i class="iconfont icon-home"></i><span>李宸墨的个人博客</span>
    </div>
    <div class="blog-detail-body">
      <div class="blog-detail">
        <mavon-editor
            class="md"
            :value="blogContent"
            :subfield="prop.subfield"
            :defaultOpen="prop.defaultOpen"
            :toolbarsFlag="prop.toolbarsFlag"
            :editable="prop.editable"
            :scrollStyle="prop.scrollStyle"
        ></mavon-editor>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "blogDetail",
        computed: {
            prop() {
                let data = {
                    subfield: false,// 单双栏模式
                    defaultOpen: 'preview',//edit： 默认展示编辑区域 ， preview： 默认展示预览区域
                    editable: false,
                    toolbarsFlag: false,
                    scrollStyle: true
                }
                return data
            }
        },
        data() {
            return {
                blogContent: ''
            }
        },
        mounted() {
            this.getBlogDetail();
        },
        methods: {
            getBlogDetail() {
                let blogId = this.$route.query.blogId
                this.$http.get(
                    'blog/getBlogDetail',
                    {params: {blogId: blogId}}
                ).then(res => {
                    if (res.data.success) {
                        this.blogContent = res.data.data.blogContent;
                    }
                })
            }
        }
    }
</script>

<style>
  .blog-detail-body {
    display: flex;
    justify-content: center;
  }

  .blog-detail {
    width: 50vw;
    height: 95vh;
    overflow: auto;
    opacity: .8;
  }

</style>